import { Paper } from '@mui/material'
import { useEffect, useState, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { exchangesListSelector } from 'redux/selectors/settings'

import Table from 'components/Table/Table'
import { balanceLevelingApi } from 'api/balance-leveling-api'
import { getColumns } from './columns'
import { useSnackbar } from 'notistack'
import { RuleDialog } from './components/RuleDialog'
import { CreateRule } from './components/CreateRule'
import { Pagination, usePagination } from 'components/Pagination'

const useAllRulesApi = ({ page, pageSize, setLastPage }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const fetch = useCallback(async (page, pageSize) => {
    setLoading(true)
    const res = await balanceLevelingApi.getLevelingRules(page, pageSize)

    if (res) {
      setLastPage(res.length < pageSize)
      setData(res)
    }

    setLoading(false)
  }, [setLastPage])

  useEffect(() => {
    fetch(page, pageSize)
  }, [fetch, page, pageSize])

  const { enqueueSnackbar } = useSnackbar()

  const toggleRule = async (ruleId, enabled) => {
    setLoading(true)
    try {
      if (enabled) {
        await balanceLevelingApi.disableRule(ruleId)
        enqueueSnackbar(`Rule ${ruleId} disabled`, { variant: 'success' })
      } else {
        await balanceLevelingApi.enableRule(ruleId)
        enqueueSnackbar(`Rule ${ruleId} enabled`, { variant: 'success' })
      }
    } catch (e) {
      enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
    }

    fetch(page, pageSize)
  }

  const toggleMonitoring = async (ruleId, enabled) => {
    setLoading(true)
    try {
      if (enabled) {
        await balanceLevelingApi.disableMonitoring(ruleId)
        enqueueSnackbar(`Rule monitoring ${ruleId} disabled`, { variant: 'success' })
      } else {
        await balanceLevelingApi.enableMonitoring(ruleId)
        enqueueSnackbar(`Rule monitoring ${ruleId} enabled`, { variant: 'success' })
      }
    } catch (e) {
      enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
    }

    fetch(page, pageSize)
  }

  const save = useCallback(async (data) => {
    setLoading(true)

    try {
      if (data.id) {
        await balanceLevelingApi.updateRule(data)
        enqueueSnackbar(`Rule ${data.id} updated`, { variant: 'success' })
      } else {
        await balanceLevelingApi.createRule(data)
        enqueueSnackbar(`Rule ${data.id} created`, { variant: 'success' })
      }
    } catch (e) {
      enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
    }

    await fetch(page, pageSize)
  }, [fetch, enqueueSnackbar, page, pageSize])

  return { data, loading, toggleRule, toggleMonitoring, save }
}

const AllRulesContainer = styled(Paper)`
  overflow-y: auto;
  margin: 4px;
`

export const AllRules = () => {
  const {
    page,
    increment,
    decrement,
    pageSize,
    changePageSize,
    tableRef,
    isLastPage,
    setLastPage,
  } = usePagination({ name: 'blRulesPageSize' })
  const { data, loading, toggleRule, toggleMonitoring, save } = useAllRulesApi({ page, pageSize, setLastPage })
  const exchanges = useSelector(exchangesListSelector)
  const exchangesMap = useMemo(
    () =>
      exchanges.reduce(
        (acc, item) => ({
          ...acc,
          [item.internal_name]: item.name,
        }),
        {}
      ),
    [exchanges]
  )

  const [selectedRule, setSelectedRule] = useState(null)

  const columns = useMemo(
    () => getColumns(exchangesMap, toggleRule, setSelectedRule, toggleMonitoring),
    [exchangesMap, toggleRule, setSelectedRule, toggleMonitoring]
  )

  return (
    <AllRulesContainer elevation={2} id='balance_leveling_rules_table'>
      <Table title={<CreateRule setSelectedRule={setSelectedRule}/>} data={data} columns={columns} loading={loading} enableFilters />
      <Pagination
        page={page}
        increment={increment}
        decrement={decrement}
        pageSize={pageSize}
        changePageSize={changePageSize}
        tableRef={tableRef}
        isLastPage={isLastPage}
      />
      <RuleDialog
        value={selectedRule}
        onClose={() => setSelectedRule(null)}
        save={save}
      />
    </AllRulesContainer>
  )
}
